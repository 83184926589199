<template>
  <div>
    <!-- header -->
    <div :style="`height:80vh; background-size:cover; background-position:50% center; margin-top:-100px`">
    </div>

    <!-- content -->      
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="title">
          TIENDA EN LÍNEA
        </div>
        <hr class="title-line">
      </div>      
    </div>

    <div class="container-fluid">
      <div class="row mb-5">

        <!-- filter sidebar -->
        <div class="order-sm-1 col-lg-10 order-md-2 col-md-9 col-sm-7 ">
          <div class="row">
            <div class="col-lg-3 col-md-4" v-for="product in filteredProducts" :key="product.id">
              <div @click="showProductDetail(product)">
                <product :product="product" class="zoom" />
              </div>
            </div>
          </div>
        </div>
                
        <div class="order-sm-2 col-lg-2 order-md-1 col-md-3 col-sm-5 ">
          <b-input-group class="mt-4 mb-4">
            <b-form-input placeholder="buscar..." v-model="productSearch" class="text-center"></b-form-input>
              <b-input-group-append>
            </b-input-group-append>
          </b-input-group>
          <b-button @click.prevent="filterProducts(productSearch)" class="buttonsearch w-100">Buscar</b-button>
          <h6 class="primary text-center mt-3">Filtros de búsqueda</h6>
          <button @click="applyFilters()" class="btn btn-dark w-100">Aplicar filtro</button>
          <div  :key="reload" >
            <div v-for="category in categories" :key="category.id">
              <hr>
              <input type="checkbox" :id="'category'+category.id" class="mr-2" @change="checkCategories(category)" v-model="category.isSelected">
              <label :for="'category'+category.id" class="text-uppercase mt-2">
                <b>{{ category.name }}</b>                 
                </label>

              <div v-for="(subcategory, i) in category.subcategories" :key="i">
                <input  :id="'subcategoryl'+subcategory.id"  type="checkbox" class="ml-3" v-model="subcategory.isSelected" :key="subcategory.id">
                <label class="ml-2"  :for="'subcategoryl'+subcategory.id">  {{ subcategory.name }}
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  <b-modal id="modal-productDetail" hide-footer >
    <template #modal-title>
      Detalle del producto:
    </template>    
    <product-detail-vue :productDetail="productData" @close="$bvModal.hide('modal-productDetail')"/>
    
  </b-modal>      
  </div>
</template>

<script>
import Product from "../components/Product.vue";
import ProductDetailVue from "../components/ProductDetail.vue";

export default {
  props: ["categoryId"],
  components: { Product, ProductDetailVue },
  data: () => ({
    categories: [],
    url: null,
    allProducts: [],
    filteredProducts: [],
    productData: {},
    storeId: 0,
    reload: 0,
    productSearch: null,
  }),
  watch: {
    async $route(to, from) {
      await this.showAllData();
    },
  },
  async mounted() {
    await this.showAllData();
  },
  methods: {
    async showAllData() {
      this.storeId = JSON.parse(localStorage.getItem("storeId"));
      this.url = this.$store.state.url;
      await this.getCategories();
      await this.getAllProducts();
      //filtrar por parametro url
      if (this.categoryId > 0) {
        this.filterProducts();
      } else {
        const productSearch = localStorage.getItem("search");
        this.filterProducts(productSearch);
      }
    },
    applyFilters() {
      // console.log('todos los productos',this.allProducts)
      // this.filteredProducts = this.allProducts.filter(x => x.categoryId == this.categoryId && this.isSelected == true)
      //obtengo las categorias seleccionadas
      let prod = [];
      let listCategoriesSelected = this.categories.filter((x) => {
        if (x.subcategories.length > 0) {
          let isselected = x.subcategories.filter((y) => {
            if (y.isSelected) {
              prod.push(y);
              return y;
            }
          });
        }
      });
      //filtros
      this.filteredProducts = this.allProducts.filter((x) => {
        let result = prod.find((y) => y.id == x.subcategoryId);
        if (result) {
          return x;
        }
      });
    },
    filterProducts(productSearch) {
      if (!productSearch) {
        let currentCategory = this.categories.find(
          (c) => c.id == this.categoryId
        );
        currentCategory.isSelected = true;
        this.filteredProducts = this.allProducts.filter(
          (x) => x.categoryId == this.categoryId
        );
        this.checkCategories(currentCategory);
      } else {
        //buscar producto
        this.filteredProducts = this.allProducts.filter((x) => {
          return (
            x.name.toUpperCase().includes(productSearch.toUpperCase()) ||
            x.code.toUpperCase().includes(productSearch.toUpperCase())
          );
        });
        //this.checkCategories();
        this.filteredProducts = this.filteredProducts.filter(x => x.variations.length>0);

      }
    },
    async checkCategories(item) {
      //activa checkbox
      item.subcategories.forEach((element) => {
        element.isSelected = item.isSelected;
      });
      this.reload++;
      //filtrar productos
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    async getCategories() {
      try {
        const url = this.url + "categories";
        console.log('url', url)
        const req = await fetch(url);
        if (req.ok) {
          this.categories = await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        console.log('------->', error)
        //alert(error);
      }
    },

    async getAllProducts() {
      try {
        const url = this.url + "productsByStore/" + this.storeId;
        const req = await fetch(url);
        if (req.ok) {
          this.allProducts = await req.json();
          this.filteredProducts = this.allProducts.filter(x => x.variations.length>0);
          //this.filteredProducts = this.allProducts;
          console.log('allproducts', this.allProducts)

        } else {
          throw await req.text();
        }
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style>
</style>